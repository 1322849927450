<template>
  <ul class="product-badges">
    <li
      v-for="(item, index) in filteredBadgesWithoutNovinka"
      :key="index"
      class="product-badges__item"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ProductCardBadges',
  props: {
    badges: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // TODO: temporary filter, remove it when CK-115 is cancelled
    badgesWithoutDiscounts() {
      return this.badges.filter((item) => item[item.length - 1] !== '%');
    },
    filteredBadgesWithoutNovinka() {
      return this.badgesWithoutDiscounts.filter((item) => item.toLowerCase() !== 'новинка');
    },
  },
}
</script>
